import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/up-sort-arrow-grey.svg';
import minus from '../../../../assets/ui/minus.svg';

import toggleSort from '../../../../utils/toggleSort';

function RatingHead({ filter, setFilter, sort, setSort, partner, cellStyle }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const SORT_NAME = 'rating';

  useEffect(() => {
    if (searchParams.get(SORT_NAME)) {
      setSort(searchParams.get(SORT_NAME));
    }
  }, []);

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySeven tw-border" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME)} style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        {t('PARTNER_NOTE', { partner: partner.name })}
        {filter === SORT_NAME ? (
          <SortArrow sort={sort} />
        ) : (
          <span className="text-end tw-ml-2.5 align-self-center">
            <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
          </span>
        )}
      </div>
    </th>
  );
}

function RatingBody({ profile, partner, cellStyle }) {
  return (
    <td className="tw-px-3 tw-border-solid tw-border-greySeven tw-border" style={cellStyle}>
      <div className="font-12 tw-flex tw-justify-center">
        <div>
          {profile.rating !== null && profile.rating !== '' ? (
            `${parseFloat(profile.rating).toLocaleString()}/${partner.max_rating}`
          ) : (
            <img src={minus} alt="minus" />
          )}
        </div>
      </div>
    </td>
  );
}

export { RatingHead, RatingBody };
