import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useFetch from 'use-http';
import { BrowserView, MobileView } from 'react-device-detect';
import { useSearchParams } from 'react-router-dom';

import downArrow from '../../../assets/ui/arrow-down-white.svg';

import TableFeatures from '../../../tableFeatures.json';
import getPartnerTableFeatures from '../../../utils/getPartnerTableFeatures';
import MobileCard from './MobileCard';
import Table from './Table';

import './Database.scss';

function Database({ partner, hasWriteAccess, filteredCategory, isDashboard }) {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const [profileStatusList, setProfileStatusList] = useState();
  const [profiles, setProfiles] = useState([]);
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState(searchParams.get('sort'));
  const [filter, setFilter] = useState(searchParams.get('filter'));
  const [tagsFilter, setTagsFilter] = useState(searchParams.get('tags_filter'));
  const [sectorFilter, setSectorFilter] = useState(searchParams.get('sf'));
  const [categoryFilter, setCategoryFilter] = useState(searchParams.get('cf'));
  const [sbtiFilter, setSbtiFilter] = useState(searchParams.get('sbti_filter'));
  const [sbtiFilterSimple, setSbtiFilterSimple] = useState(searchParams.get('sbti_filter_simple'));
  const [statusFilter, setStatusFilter] = useState(searchParams.get('status_filter'));
  const [currentProgressFilter, setCurrentProgressFilter] = useState(searchParams.get('current_progress_filter'));
  const [actionPlanFilter, setActionPlanFilter] = useState(searchParams.get('action_plan_filter'));
  const [total, setTotal] = useState(0);
  const [tableComponent, setTableComponent] = useState();
  const [edit, setEdit] = useState([]);
  const [features, setFeatures] = useState();
  const [loading, setLoading] = useState(false);

  const { get, response } = useFetch(process.env.REACT_APP_PARTNER_API, {
    cachePolicy: 'no-cache',
  });

  const getProfiles = async (localSort, localFilter, sectorFilter, categoryFilter, sbtiFilter, sbtiFilterSimple, statusFilter, currentProgressFilter, actionPlanFilter, tagsFilter, localPage, showMore = false) => {
    // localSort and localFilter is to avoid asynchronisity of setSort and setFilter
    try {
      setLoading(true);

      await get(`/partner/${partner.id}/profiles-complete?page=${localPage}${localFilter ? `&filter=${filter}` : ''}${localSort ? `&sort=${sort}` : ''}${sectorFilter ? `&sector_filter=${sectorFilter}` : ''}${categoryFilter ? `&category_filter=${categoryFilter}` : ''}${sbtiFilter ? `&sbti_filter=${sbtiFilter}` : ''}${sbtiFilterSimple ? `&sbti_filter_simple=${sbtiFilterSimple}` : ''}${statusFilter ? `&status_filter=${statusFilter}` : ''}${currentProgressFilter ? `&current_progress_filter=${currentProgressFilter}` : ''}${actionPlanFilter ? `&action_plan_filter=${actionPlanFilter}` : ''}${tagsFilter ? `&tags_filter=${tagsFilter}` : ''}&domain=${partner.domain}`);
      if (response.ok && response.data && response.data.profiles) {
        setTotal(response.data.total);
        const editArray = [];
        response.data.profiles.forEach((profile) => {
          editArray.push({ id: profile.id, edit: false });
        });

        if (showMore) {
          setProfiles([...profiles, ...response.data.profiles]);
          setEdit([...edit, ...editArray]);
        } else {
          setProfiles(response.data.profiles);
          setEdit(editArray);
        }
      } else {
        setProfiles([]);
        setTotal(0);
        setEdit([]);
      }

      setLoading(false);
    } catch (error) {
      throw error;
    }
  };

  const getProfileStatusList = async () => {
    try {
      await get(`/partner/${partner.id}/profile-status`);
      if (response.ok) {
        setProfileStatusList(response.data);
      }
    } catch (error) {
      throw error;
    }
  };

  const togglePage = () => { // RENAME FUNCTION
    const localPage = page + 1;
    setPage(localPage);
    getProfiles(sort, filter, sectorFilter, categoryFilter, sbtiFilter, sbtiFilterSimple, statusFilter, currentProgressFilter, actionPlanFilter, tagsFilter, localPage, true);
  };

  useEffect(() => {
    const localPage = 0;
    setPage(localPage);
    setSectorFilter(searchParams.get('sf'));
    setCategoryFilter(searchParams.get('cf'));
    setSbtiFilter(searchParams.get('sbti_filter'));
    setSbtiFilterSimple(searchParams.get('sbti_filter_simple'));
    setStatusFilter(searchParams.get('status_filter'));
    setCurrentProgressFilter(searchParams.get('current_progress_filter'));
    setActionPlanFilter(searchParams.get('action_plan_filter'));
    setTagsFilter(searchParams.get('tags_filter'));
    getProfiles(sort, filter, searchParams.get('sf'), searchParams.get('cf'), searchParams.get('sbti_filter'), searchParams.get('sbti_filter_simple'), searchParams.get('status_filter'), searchParams.get('current_progress_filter'), searchParams.get('action_plan_filter'), searchParams.get('tags_filter'), localPage);
    getProfileStatusList();
  }, [searchParams.get('tags_filter'), searchParams.get('filter'), searchParams.get('sort'), searchParams.get('sf'), searchParams.get('cf'), searchParams.get('sbti_filter'), searchParams.get('sbti_filter_simple'), searchParams.get('status_filter'), searchParams.get('current_progress_filter'), searchParams.get('action_plan_filter')]);

  useEffect(() => {
    const getTableFeatures = async () => {
      try {
        const features = await getPartnerTableFeatures(partner.id);
        if (features.length > 0) {
          setFeatures(features);
          setTableComponent({
            tags: features.includes(TableFeatures.tags),
            turnover: features.includes(TableFeatures.turnover),
            sector: features.includes(TableFeatures.sector),
            category: features.includes(TableFeatures.category),
            climateAction: features.includes(TableFeatures.climateAction),
            scope12: features.includes(TableFeatures.scope12),
            scope3: features.includes(TableFeatures.scope3),
            objScope12: features.includes(TableFeatures.objScope12),
            objScope3: features.includes(TableFeatures.objScope3),
            targetSbt: features.includes(TableFeatures.targetSbt),
            targetSbtSimple: features.includes(TableFeatures.targetSbtSimple),
            currentProgress: features.includes(TableFeatures.currentProgress),
            scoreCdp: features.includes(TableFeatures.scoreCdp),
            status: features.includes(TableFeatures.status),
            profileRating: features.includes(TableFeatures.profileRating),
            profileGroup: features.includes(TableFeatures.profileGroup),
            actionsCompleted: features.includes(TableFeatures.actionsCompleted),
            profileTurnover: features.includes(TableFeatures.profileTurnover),
            profileCarbonWeight: features.includes(TableFeatures.profileCarbonWeight),
          });
        }
      } catch (error) {
        throw new Error(error);
      }
    };
    getTableFeatures();
  }, []);

  return (
    <section className={`${isDashboard ? 'px-0' : 'container'}`}>
      <div className="row h-100">
        <div className="col-12 tw-p-0">
          <p className="tw-text-greyHeavy tw-mb-3 md:tw-mb-0 tw-text-xs">
            {total} {t('SUPPLIER')}{total > 1 && 's'} {filteredCategory && `- ${t('CATEGORY')} ${filteredCategory}`}
          </p>
          {isDashboard ? (
            <Table
              profiles={profiles}
              partner={partner}
              profileStatusList={profileStatusList}
              tableComponent={tableComponent}
              filter={filter}
              setFilter={setFilter}
              sort={sort}
              setSort={setSort}
              categoryFilter={categoryFilter}
              hasWriteAccess={hasWriteAccess}
              edit={edit}
              setEdit={setEdit}
              isDashboard={isDashboard}
              loading={loading}
              total={total}
              togglePage={togglePage}
              features={features}
              getProfiles={getProfiles}
              sectorFilter={sectorFilter}
              sbtiFilter={sbtiFilter}
              sbtiFilterSimple={sbtiFilterSimple}
              statusFilter={statusFilter}
              currentProgressFilter={currentProgressFilter}
              actionPlanFilter={actionPlanFilter}
              tagsFilter={tagsFilter}
            />
          ) : (
            <>
              <BrowserView>
                <Table
                  profiles={profiles}
                  partner={partner}
                  profileStatusList={profileStatusList}
                  tableComponent={tableComponent}
                  filter={filter}
                  setFilter={setFilter}
                  sort={sort}
                  setSort={setSort}
                  categoryFilter={categoryFilter}
                  hasWriteAccess={hasWriteAccess}
                  edit={edit}
                  setEdit={setEdit}
                  isDashboard={isDashboard}
                  loading={loading}
                  total={total}
                  togglePage={togglePage}
                  features={features}
                  getProfiles={getProfiles}
                  sectorFilter={sectorFilter}
                  sbtiFilter={sbtiFilter}
                  sbtiFilterSimple={sbtiFilterSimple}
                  statusFilter={statusFilter}
                  currentProgressFilter={currentProgressFilter}
                  actionPlanFilter={actionPlanFilter}
                  tagsFilter={tagsFilter}
                />
              </BrowserView>
              <MobileView className="mb-5">
                {profiles.map((profile, index) => (
                  <MobileCard key={index} profile={profile} index={index} tableComponent={tableComponent} />
                ))}
                <nav id="more-items" className={`mx-auto w-100 my-5 text-center ${(loading || (total === profiles.length) || total <= 10) ? 'd-none' : ''}`} aria-label="Page navigation">
                  <button type="button" className="btn add-more-button text-white mx-auto" style={{ background: partner.primary_color }} onClick={() => togglePage()}>
                    <img className="add-more-icon align-self-center me-2" src={downArrow} alt="arrow down" />
                    {t('SHOW_MORE')}
                  </button>
                </nav>
              </MobileView>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default Database;
