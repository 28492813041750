import React, { useState, useEffect } from 'react';
import { useFetch } from 'use-http';
import { useTranslation } from 'react-i18next';
import tailwindConfig from '../../../tailwind.config';
import handleResponse from '../../../utils/handleResponse';
import arrowLeft from '../../../assets/ui/arrow-left.svg';

function CreateTagForm({ partner, onCancel, onCreate, partnerTags }) {
  const [tagName, setTagName] = useState('');
  const [error, setError] = useState('');
  const { t } = useTranslation();

  const { post, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partner.id}/tags`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  useEffect(() => {
    setError('');
  }, [tagName]);

  const handleInputChange = (event) => {
    setTagName(event.target.value);
  };

  const isTagNameExists = (name) => {
    return partnerTags.some((tag) => tag.name.toLowerCase() === name.toLowerCase());
  };

  const handleCreate = async () => {
    if (isTagNameExists(tagName)) {
      setError(t('TAG_NAME_EXISTS'));
      return;
    }

    const body = {
      name: tagName,
      color: null,
    };

    try {
      const createdTag = await post(body);

      if (response.ok && createdTag?.id) {
        onCreate({ id: createdTag.id, name: tagName, color: null });
        setTagName('');
        setError('');
      }
    } catch (error) {
      handleResponse(response);
    }
  };

  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-border tw-border-solid tw-border-greySix tw-rounded-[4px] tw-p-4 tw-mt-5">
      <div className="tw-flex tw-items-center tw-mb-4">
        <button
          type="button"
          onClick={onCancel}
          className="tw-border-none tw-bg-white tw-p-0"
        >
          <img src={arrowLeft} alt="back" className="tw-w-6 tw-h-6 tw-mr-3" />
        </button>
        <span className="tw-font-medium tw-text-greyOne tw-font-moskauGrotesk">{t('CREATE_TAG')}</span>
      </div>
      <input
        id="tagName"
        type="text"
        placeholder="Nom de l'étiquette"
        className={`tw-rounded-t-md tw-rounded-b-none tw-text-greyFour tw-font-moskauGrotesk tw-px-5 tw-py-2.5 tw-font-normal tw-text-base tw-w-full tw-border tw-border-solid tw-border-greySix ${error ? 'tw-border-red tw-bg-red/5 focus:tw-border-red focus:tw-border' : 'tw-border-greySix'} focus:tw-border-2 focus:tw-outline-none focus:tw-border-secondarySkyBlue`}
        value={tagName}
        onChange={handleInputChange}
      />

      <button
        type="button"
        disabled={tagName === ''}
        className="tw-flex tw-items-center tw-bg-greyEight tw-border tw-border-x tw-border-y-0 tw-border-b tw-border-solid tw-border-greySix tw-rounded-t-none tw-rounded-b-md tw-p-[14px]"
        onClick={handleCreate}
      >
        <span
          className="tw-border-none tw-bg-greyEight tw-text-sm tw-text-primaryNewBlue tw-font-moskauGrotesk tw-font-medium -tw-mb-1"
        >
          Créer
        </span>
        {tagName && (
          <div className="tw-ml-4 tw-flex tw-items-center tw-rounded tw-py-[6px] tw-px-3" style={{ backgroundColor: tailwindConfig.theme.colors.tagsGrey }}>
            <span className="tw-text-sm tw-font-moskauGrotesk tw-font-medium tw-text-greyOne -tw-mb-[4px]">{tagName}</span>
          </div>
        )}
      </button>
      {error && <span className="tw-text-sm tw-text-redError tw-font-moskauGrotesk tw-mt-1">{error}</span>}
    </div>
  );
}

export default CreateTagForm;
