import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

function Tags({ tags }) {
  const [value, setValue] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const handleTagsFilter = (e) => {
    if (e.target.value === '') {
      searchParams.delete('tags_filter');
    } else {
      searchParams.set('tags_filter', e.target.value);
    }
    setSearchParams(searchParams);
  };

  useEffect(() => {
    if (searchParams.get('tags_filter')) {
      setValue(searchParams.get('tags_filter'));
    } else {
      setValue('');
    }
  }, [searchParams.get('tags_filter')]);

  return (
    <select
      name="tags-filter"
      id="tags-filter"
      className="tw-min-h-11 tw-border tw-border-solid tw-rounded-lg tw-border-greySeven tw-text-greyThree tw-text-sm tw-pl-4 tw-pr-8 filter-select tw-w-full sm:tw-w-60"
      value={value}
      onChange={(e) => handleTagsFilter(e)}
    >
      <option value="">{t('TAGS')}</option>
      {tags && tags.map((tag) => (
        <option key={tag.id} value={tag.id}>
          {tag.name}
        </option>
      ))}
    </select>
  );
}

export default Tags;
