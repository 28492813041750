import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/up-sort-arrow-grey.svg';
import minus from '../../../../assets/ui/minus.svg';

import toggleSort from '../../../../utils/toggleSort';

function PartnerTurnoverHead({ filter, setFilter, sort, setSort, partner, cellStyle }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const SORT_NAME = 'partner_turnover';

  useEffect(() => {
    if (searchParams.get(SORT_NAME)) {
      setSort(searchParams.get(SORT_NAME));
    }
  }, []);

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySeven tw-border tw-min-w-40" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME)} style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        <div>
          {t('TURNOVER_WITH')} {partner.name}
          {filter === SORT_NAME ? (
            <SortArrow sort={sort} />
          ) : (
            <span className="text-end tw-ml-2.5 align-self-center">
              <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
            </span>
          )}
          <br />
          <span id="climate-action-subtitle" className="font-10 text-muted">
            {t('IN_MILLION_EUROS')}
          </span>
        </div>
      </div>
    </th>
  );
}

function PartnerTurnoverBody({ profile, cellStyle }) {
  return (
    <td className="tw-px-3 tw-border-solid tw-border-greySeven tw-border" style={cellStyle}>
      <div className="font-12 tw-flex tw-justify-center">
        <div>
          {profile?.partner_turnover !== null ? `${profile?.partner_turnover} mn€` : (
            <img src={minus} alt="minus" />
          )}
        </div>
      </div>
    </td>
  );
}

export { PartnerTurnoverHead, PartnerTurnoverBody };
