import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Features from '../../../features.json';
import Presentation from './Presentation';
import Card from './Card';
import verifyAuthorization from '../../../utils/verifyAuthorization';

export default function DashboardHome() {
  const { partner, features, session } = useOutletContext();

  const { t } = useTranslation();

  const cards = [
    {
      name: t('PARTNER_DASHBOARD.HOME.CARD.SUPPLIERS.TITLE'),
      text: t('PARTNER_DASHBOARD.HOME.CARD.SUPPLIERS.SUBTITLE'),
      cta: t('PARTNER_DASHBOARD.HOME.CARD.SUPPLIERS.CTA'),
      link: '/dashboard/suppliers',
      active: verifyAuthorization(session, ['ROLE_PARTNER', 'ROLE_PARTNER_ADMIN', 'ROLE_PARTNER_AUTO_EDIT_PROFILES_ANSWERS', 'ROLE_ADMIN']),
    },
    {
      name: t('PARTNER_DASHBOARD.HOME.CARD.RESSOURCES.TITLE'),
      text: t('PARTNER_DASHBOARD.HOME.CARD.RESSOURCES.SUBTITLE'),
      cta: t('PARTNER_DASHBOARD.HOME.CARD.RESSOURCES.CTA'),
      link: '/dashboard/home',
      active: verifyAuthorization(session, ['ROLE_ADMIN']),
    },
    {
      name: t('PARTNER_DASHBOARD.HOME.CARD.CATEGORIES.TITLE'),
      text: t('PARTNER_DASHBOARD.HOME.CARD.CATEGORIES.SUBTITLE'),
      cta: t('PARTNER_DASHBOARD.HOME.CARD.CATEGORIES.CTA'),
      link: '/dashboard/category',
      active: verifyAuthorization(session, ['ROLE_ADMIN']),
    },
    {
      name: t('PARTNER_DASHBOARD.HOME.CARD.STATS.TITLE'),
      text: t('PARTNER_DASHBOARD.HOME.CARD.STATS.SUBTITLE'),
      cta: t('PARTNER_DASHBOARD.HOME.CARD.STATS.CTA'),
      link: '/dashboard/statistics',
      active: verifyAuthorization(session, ['ROLE_PARTNER_ADMIN', 'ROLE_ADMIN', 'ROLE_PARTNER']),
    },
    {
      name: t('PARTNER_DASHBOARD.HOME.CARD.API.TITLE'),
      text: t('PARTNER_DASHBOARD.HOME.CARD.API.SUBTITLE'),
      cta: t('PARTNER_DASHBOARD.HOME.CARD.API.CTA'),
      link: '/dashboard/home',
      active: verifyAuthorization(session, ['ROLE_ADMIN']),
    },
  ];

  return (
    <div className="tw-w-full tw-flex tw-flex-col tw-p-7 xl:tw-p-12">
      <div className="lg:tw-w-[39rem]">
        {features.includes(Features.presentation) && <Presentation text={partner?.presentation_content} />}
        <div className=" tw-flex tw-items-center tw-flex-wrap tw-gap-6 tw-mt-6">
          {cards.map((card, index) => (
            card.active && (
              <Card
                key={index}
                cardName={card.name}
                cardText={card.text}
                cardCta={card.cta}
                cardLink={card.link}
              />
            )
          ))}
        </div>
      </div>
    </div>
  );
}
