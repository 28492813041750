import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useFetch } from 'use-http';

import SortArrow from '../SortArrow';
import upSortArrowGrey from '../../../../assets/ui/up-sort-arrow-grey.svg';
import minus from '../../../../assets/ui/minus.svg';

import toggleSort from '../../../../utils/toggleSort';
import handleResponse from '../../../../utils/handleResponse';

function CarbonWeightHead({ filter, setFilter, sort, setSort, partner, cellStyle }) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { t } = useTranslation();

  const SORT_NAME = 'partner_carbon_weight';

  useEffect(() => {
    if (searchParams.get(SORT_NAME)) {
      setSort(searchParams.get(SORT_NAME));
    }
  }, []);

  return (
    <th className="font-12 tw-px-3 tw-font-medium tw-cursor-pointer tw-border-solid tw-border-greySeven tw-border" onClick={() => toggleSort(searchParams, setSearchParams, sort, setSort, filter, setFilter, SORT_NAME)} style={cellStyle}>
      <div className="tw-flex tw-justify-center">
        <div>
          {t('CARBON_WEIGHT_AT')} {partner.name}
          {filter === SORT_NAME ? (
            <SortArrow sort={sort} />
          ) : (
            <span className="text-end tw-ml-2.5 align-self-center">
              <img className="link-sort-icon align-middle" src={upSortArrowGrey} alt="name" />
            </span>
          )}
          <br />
          <span id="climate-action-subtitle" className="font-10 text-muted">
            {t('IN_KILOTONS')}
          </span>
        </div>
      </div>
    </th>
  );
}

function CarbonWeightBody({ profile, cellStyle, categoryFilter, partnerId, loading }) {
  const [carbonWeight, setCarbonWeight] = useState();

  const { get, response } = useFetch(`${process.env.REACT_APP_PARTNER_API}/partner/${partnerId}`, {
    cachePolicy: 'no-cache',
    credentials: 'include',
  });

  const getCarbonWeight = async () => {
    try {
      let carbonWeight;
      const responseData = await get(`/profiles/${profile.id}/categories-carbon-weight${categoryFilter ? `?filter_id=${categoryFilter}` : ''}`);

      if (response.ok) {
        if (responseData) {
          carbonWeight = responseData.carbonWeight / 1000;
        }
      }

      return carbonWeight;
    } catch (error) {
      handleResponse(error);
      throw new Error(error);
    }
  };

  useEffect(() => {
    const fetchCarbonWeight = async () => {
      const carbonData = await getCarbonWeight();
      setCarbonWeight(carbonData);
    };

    if (!loading) {
      fetchCarbonWeight();
    }
  }, [profile, loading]);

  return (
    <td className="tw-px-3 tw-border-solid tw-border-greySeven tw-border" style={cellStyle}>
      <div className="font-12 tw-flex tw-justify-center">
        <div>
          {carbonWeight ? `${carbonWeight} kt` : (
            <img src={minus} alt="minus" />
          )}
        </div>
      </div>
    </td>
  );
}

export { CarbonWeightHead, CarbonWeightBody };
