import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

function StatusTemplate({ title, description, color, backgroundColor, additionalClass }) {
  return (
    <div className={`border-8 ${additionalClass}`} style={{ padding: '16px', backgroundColor, color }}>
      <h4 className="fw-700 font-14">
        {title}
      </h4>
      <p className="fw-400 font-14 mb-0 dark-L-color">
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {description}
        </ReactMarkdown>
      </p>
    </div>
  );
}

export default StatusTemplate;
