import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import './Suppliers.scss';
import Sbti from './Filters/Sbti';
import Status from './Filters/Status';
import Database from '../../Features/Database/Database';
import SbtiSimple from './Filters/SbtiSimple';
import CurrentProgress from './Filters/CurrentProgress';
import ActionPlanCompleted from './Filters/ActionPlanCompleted';
import getPartnerTableFeatures from '../../../utils/getPartnerTableFeatures';
import TableFeatures from '../../../tableFeatures.json';
import ProfileSearch from './Filters/ProfileSearch/ProfileSearch';
import Tags from './Filters/Tags';

function Suppliers() {
  const [features, setFeatures] = useState([]);
  const { partner, isDashboard, hasWriteAccess } = useOutletContext();
  const { t } = useTranslation();

  useEffect(() => {
    const getTableFeatures = async () => {
      try {
        const features = await getPartnerTableFeatures(partner.id);

        if (features.length > 0) {
          setFeatures(features);
        }
      } catch (error) {
        throw new Error(error);
      }
    };
    getTableFeatures();
  }, []);

  return (
    <div className="tw-w-full tw-p-7 xl:tw-p-12 tw-flex tw-flex-col tw-max-w-full">
      <h2 className="tw-text-newBrand tw-text-2xl tw-font-semibold tw-mb-3">
        {t('SUPPLIERS')}
      </h2>
      <div className="tw-w-full tw-flex tw-flex-col">
        <ProfileSearch partner={partner} />
        <div className="tw-flex tw-gap-3 tw-flex-wrap">
          {features.includes(TableFeatures.targetSbt) ? <Sbti /> : null}
          {features.includes(TableFeatures.targetSbtSimple) ? <SbtiSimple /> : null}
          {features.includes(TableFeatures.currentProgress) ? <CurrentProgress /> : null}
          {features.includes(TableFeatures.actionsCompleted) ? <ActionPlanCompleted /> : null}
          {features.includes(TableFeatures.status) ? <Status partner={partner} /> : null}
          {features.includes(TableFeatures.tags) ? <Tags tags={partner.tags} /> : null}
        </div>
      </div>
      <div className="tw-w-full tw-mt-5">
        <Database hasWriteAccess={hasWriteAccess} partner={partner} isDashboard={isDashboard} />
      </div>
    </div>

  );
}

export default Suppliers;
